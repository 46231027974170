<template>
  <v-card outlined>
    <v-card-title>
      <v-row justify="center" align="center">
        <v-col cols="12" class="text-center">
          <p class="mb-0"><v-icon color="red">bug_report</v-icon> Bug Report</p>
          <hr />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <p>
        Para reportar falhas do sistema, por gentileza, envie um e-mail para
        <strong>suporte@ic.unicamp.br</strong> com as informações detalhadas do
        problema apresentado.
      </p>
      <p>
        Estamos trabalhando para integrar com o sistema interno de abertura de
        tickets para suporte automatizado.
      </p>
      <p>Agradecemos o seu feedback.</p>
    </v-card-text>
    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Feedback",
};
</script>

<style scoped>
</style>