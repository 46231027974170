 <template>
    <v-card>
      <v-card-title class="headline justify-center">SiGPos</v-card-title>
      <v-card-text class="text-center">
        <p>
          Sistema de gerenciamento da pós graduação
          <br />Versão Beta
        </p>
      </v-card-text>
    </v-card>
</template>
<script>
export default {
  name: "Info",
};
</script>