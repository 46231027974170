var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "", dark: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.toggleDrawer()
              }
            }
          }),
          _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
          _c(
            "v-spacer",
            [
              _c("v-toolbar-title", [
                _c("span", [
                  _vm._v(
                    "SiGPos - Sistema de Gerenciamento da Pós-Graduação do IC-Unicamp"
                  )
                ]),
                _vm.mode == "development"
                  ? _c(
                      "span",
                      { staticClass: "ml-3" },
                      [
                        _c(
                          "v-chip",
                          {
                            attrs: { color: "success", outlined: "", small: "" }
                          },
                          [_vm._v("Homologação")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.mode == "local"
                  ? _c(
                      "span",
                      { staticClass: "ml-3" },
                      [
                        _c(
                          "v-chip",
                          { attrs: { color: "blue", outlined: "", small: "" } },
                          [_vm._v("Local")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }