var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "headline justify-center" }, [
        _vm._v("SiGPos")
      ]),
      _c("v-card-text", { staticClass: "text-center" }, [
        _c("p", [
          _vm._v(" Sistema de gerenciamento da pós graduação "),
          _c("br"),
          _vm._v("Versão Beta ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }