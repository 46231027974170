var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c(
                    "p",
                    { staticClass: "mb-0" },
                    [
                      _c("v-icon", { attrs: { color: "red" } }, [
                        _vm._v("bug_report")
                      ]),
                      _vm._v(" Bug Report")
                    ],
                    1
                  ),
                  _c("hr")
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-text", [
        _c("p", [
          _vm._v(
            " Para reportar falhas do sistema, por gentileza, envie um e-mail para "
          ),
          _c("strong", [_vm._v("suporte@ic.unicamp.br")]),
          _vm._v(" com as informações detalhadas do problema apresentado. ")
        ]),
        _c("p", [
          _vm._v(
            " Estamos trabalhando para integrar com o sistema interno de abertura de tickets para suporte automatizado. "
          )
        ]),
        _c("p", [_vm._v("Agradecemos o seu feedback.")])
      ]),
      _c("v-card-actions")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }