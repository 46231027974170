<template>
  <div>
    <v-app-bar app clipped-left dark>
      <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
      <v-divider class="mx-4" vertical></v-divider>
      <v-spacer>
        <v-toolbar-title>
          <span
            >SiGPos - Sistema de Gerenciamento da Pós-Graduação do
            IC-Unicamp</span
          >
          <span v-if="mode == 'development'" class="ml-3">
            <v-chip color="success" outlined small>Homologação</v-chip> </span
          ><span v-if="mode == 'local'" class="ml-3">
            <v-chip color="blue" outlined small>Local</v-chip>
          </span>
        </v-toolbar-title>
      </v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  emits: ["toggle-drawer"],
  name: "Bar",
  props: {},

  data() {
    return { mode: "" };
  },

  created() {
    this.mode = process.env.NODE_ENV;
  },

  methods: {
    toggleDrawer() {
      this.$emit("toggle-drawer", "drawer");
    },
  },
};
</script>

<style>
</style>